/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'pJ9pTu9WuxZ3QJZHqrpigg.png': { uri: '/images/pJ9pTu9WuxZ3QJZHqrpigg.png' },
'c5qbbff7sCJLrpKngWNabS.png': { uri: '/images/c5qbbff7sCJLrpKngWNabS.png' },
'hKCEYR85VTdsUfck9tCMgn.png': { uri: '/images/hKCEYR85VTdsUfck9tCMgn.png' },
'vkHA6r2jK6Fbdck43U8jQz.png': { uri: '/images/vkHA6r2jK6Fbdck43U8jQz.png' },
'mR85dsGFSP6aHZThLE1298.png': { uri: '/images/mR85dsGFSP6aHZThLE1298.png' },
'9VwgkAnEbPTWL2SyYz8jYa.png': { uri: '/images/9VwgkAnEbPTWL2SyYz8jYa.png' },
'jhAfBeKv8uhshoSnNuaBFu.png': { uri: '/images/jhAfBeKv8uhshoSnNuaBFu.png' },
'qqiN4ifoREfJD54pWSMYdt.png': { uri: '/images/qqiN4ifoREfJD54pWSMYdt.png' },
'gnE4f8BazQug6uWCStdEDJ.png': { uri: '/images/gnE4f8BazQug6uWCStdEDJ.png' },
'76EdgBgfnq2mQ7326thCRR.png': { uri: '/images/76EdgBgfnq2mQ7326thCRR.png' },
'8iGZRU56ngMx991K3uZZ9S.png': { uri: '/images/8iGZRU56ngMx991K3uZZ9S.png' },
'9hp1uW87QAJ4xJzyA8iqvm.png': { uri: '/images/9hp1uW87QAJ4xJzyA8iqvm.png' },
'sibE3ogVhb6GDT9bqaPN8u.png': { uri: '/images/sibE3ogVhb6GDT9bqaPN8u.png' },
'uWXmjS54nQHhdokXn83KdD.png': { uri: '/images/uWXmjS54nQHhdokXn83KdD.png' },
'gQfJDbHKc2ky4CBTxMq1ug.png': { uri: '/images/gQfJDbHKc2ky4CBTxMq1ug.png' },
'bupHWBLr1kVhiSVmBv4siW.png': { uri: '/images/bupHWBLr1kVhiSVmBv4siW.png' },
'gyjgHwBvugQGXTgshXxRm7.png': { uri: '/images/gyjgHwBvugQGXTgshXxRm7.png' },
'fk9bBySAivvz1YvcdvdYM1.png': { uri: '/images/fk9bBySAivvz1YvcdvdYM1.png' },
'8GafKUQmwiamUny4E7V4TT.png': { uri: '/images/8GafKUQmwiamUny4E7V4TT.png' },
'vNofLR7G18equZgyLjfeoB.png': { uri: '/images/vNofLR7G18equZgyLjfeoB.png' },
'p5gSP5xuk6pE3MJHXB4jux.png': { uri: '/images/p5gSP5xuk6pE3MJHXB4jux.png' },
'bvj72tmSMD5GRmF55JuRpg.png': { uri: '/images/bvj72tmSMD5GRmF55JuRpg.png' },
'fejvZv9Ngj848yE4QxwuV8.png': { uri: '/images/fejvZv9Ngj848yE4QxwuV8.png' },
'bYu2PqNzFwqMSA8e7ZgRHU.png': { uri: '/images/bYu2PqNzFwqMSA8e7ZgRHU.png' },
'gadn9sEmzKz9AfqXBeMSe9.png': { uri: '/images/gadn9sEmzKz9AfqXBeMSe9.png' },
'wdKQ3h1ZdiqJx2K9SnKf9W.png': { uri: '/images/wdKQ3h1ZdiqJx2K9SnKf9W.png' },
's1EnPXWgTH1PK4U3qWoPAi.png': { uri: '/images/s1EnPXWgTH1PK4U3qWoPAi.png' },
'nuTyaBUzERYkyTaGoTZyds.png': { uri: '/images/nuTyaBUzERYkyTaGoTZyds.png' },
'oL1A4q5Q32HfYDmcpwEyy9.png': { uri: '/images/oL1A4q5Q32HfYDmcpwEyy9.png' },
'uReQTx3AbSntuL9JWJKo3S.png': { uri: '/images/uReQTx3AbSntuL9JWJKo3S.png' },
'a74Wftxp97rS5woxzMHhD3.png': { uri: '/images/a74Wftxp97rS5woxzMHhD3.png' },
'jXRi2ZTe4d9kcQqJvoUhbx.png': { uri: '/images/jXRi2ZTe4d9kcQqJvoUhbx.png' },
'f5HjPFmWMjHcTArG4QrcDs.png': { uri: '/images/f5HjPFmWMjHcTArG4QrcDs.png' },
'wPEzhCqtzpJUw1Ep3FHK7t.png': { uri: '/images/wPEzhCqtzpJUw1Ep3FHK7t.png' },
'4geRw9JTuTkA2K4i15UkNB.png': { uri: '/images/4geRw9JTuTkA2K4i15UkNB.png' },
'fCBET33EyincF7bH4uQyng.png': { uri: '/images/fCBET33EyincF7bH4uQyng.png' },
'ncAqxEwKUbiVMAA3V1Pnt8.png': { uri: '/images/ncAqxEwKUbiVMAA3V1Pnt8.png' },
'n67hwMD2f9C9hwuv4PNx7M.png': { uri: '/images/n67hwMD2f9C9hwuv4PNx7M.png' },
'5BwbtfkeDACiWKJe67jW4v.png': { uri: '/images/5BwbtfkeDACiWKJe67jW4v.png' },
'9414y8MjqCWfrs78YNQcc9.png': { uri: '/images/9414y8MjqCWfrs78YNQcc9.png' },
'm2iHSb35mDmTejBNjHd92o.png': { uri: '/images/m2iHSb35mDmTejBNjHd92o.png' },
'42SpA9qAfyTatd5Hc26EF4.png': { uri: '/images/42SpA9qAfyTatd5Hc26EF4.png' },
'1oDAfEfCon4KGDCxGyiXte.png': { uri: '/images/1oDAfEfCon4KGDCxGyiXte.png' },
'pybfsTrx9zW9jPeTZGZFuV.png': { uri: '/images/pybfsTrx9zW9jPeTZGZFuV.png' },
'eHfJ9fua16ykwk1avjucdh.png': { uri: '/images/eHfJ9fua16ykwk1avjucdh.png' },
'wJPsu92cRtLasadwzTPdu8.png': { uri: '/images/wJPsu92cRtLasadwzTPdu8.png' },
'vppCV5ZaD7Mt65vcehB1hU.png': { uri: '/images/vppCV5ZaD7Mt65vcehB1hU.png' },
'4vGV7TJPzQ4Nm8CYKsPVWF.jpg': { uri: '/images/4vGV7TJPzQ4Nm8CYKsPVWF.jpg' },
'gtMVEAVaEGgJ3hMB8GNpVJ.png': { uri: '/images/gtMVEAVaEGgJ3hMB8GNpVJ.png' },
'wtaB2MEaXpqQHnoCxMY1Ca.png': { uri: '/images/wtaB2MEaXpqQHnoCxMY1Ca.png' },
'gRhSYqMCczAAGn2Mroxv1G.jpg': { uri: '/images/gRhSYqMCczAAGn2Mroxv1G.jpg' },
'36bK7NUUG6k8vHFi774pXh.png': { uri: '/images/36bK7NUUG6k8vHFi774pXh.png' },
'dHrNrCgnz5GbTbpwD1wXvo.png': { uri: '/images/dHrNrCgnz5GbTbpwD1wXvo.png' },
'gHExosNuiD6J5EWXSTFrRf.png': { uri: '/images/gHExosNuiD6J5EWXSTFrRf.png' }
}

export default imageStaticSourcesByFileName
